<template>
  <div>
    <p>{{ canbus.code }} : {{ canbus.value }}</p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "canbus",
  data() {
    return {};
  },
  async created() {
    await this.$store.dispatch("canbus/getCanbus");
  },
  computed: {
    ...mapState("canbus", ["canbus"])
  },
  methods: {}
};
</script>

<style lang="scss">
</style>